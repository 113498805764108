import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FailBlock from "./FailBlock";
import { toast } from 'react-toastify';
import { createInspection, deleteItemResultFiles, fetchInspectionItems, fetchItemResultFiles, fetchItemResults, saveInspection, updateItemResultFiles } from "services/inspection";
import { resetBlockFlags,  } from "features/inspection/inspectionSlice";
import { convertToBase64} from "utils/utility";
import InnerLoading from "components/InnerLoading";
import { getPrintableDailyInspectionReport } from "services/inspectReport/inspectReportService";
import { useNavigate ,useSearchParams} from 'react-router-dom';


function InspectionForm({ 
  selectedRoom,
  roomInspection,  
  setInspectionScore, 
  canSaveInspection,
  setRoomListLoading,
  onOpenDeleteInspection,
 }) {
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    updateItemResults: { data: updatedItemResults, success: updatedItemResultsSuccess, error: updatedItemResultsError, loading: updatedItemResultsLoading },
    updateItemResultFiles: { data: updatedItemResultFiles, success: updatedItemResultFilesSuccess, error: updatedItemResultFilesError, loading: updateItemResultFilesLoading },
    deleteItemResultFiles: {success: deleteItemResultFilesSuccess, loading: deleteItemResultFilesLoading},
    itemResultFiles: { data: itemResultFilesData, success: itemResultFilesSuccess, error: itemResultFilesError },
    itemResults: { data: itemResultsData, success: itemResultsSuccess, error: itemResultsError, loading: itemsResultsLoading },
    deleteInspection: { loading: deleteInspectionLoading, success: deleteInspectionSuccess},
    inspectionItems: { data: inspectionItemsData, loading: inspectionItemsLoading, success: inspectionsItemSuccess, error: inspectionsItemSuccessError },
  } = useSelector(({ inspection }) => inspection);
  const user = useSelector(({ auth }) => auth.data?.user);
  const isSuperAdmin = user?.role === "super_admin";
  const isDistrictAdmin = user?.role === "district_admin";


  const [questions, setQuestions] = useState([]);
  const [scoreText, setScoreText] = useState("");
  const [roomId, setRoomId] = useState();
  const [inspectionId, setInspectionId] = useState();
  const [questionFilesTable, setQuestionFilesTable] = useState({});
  const [questionResultsTable, setQuestionResultsTable] = useState({});
  const [questionsLoading, setQuestionsLoading] = useState(false)
  const [removedFiles, setRemovedFiles] = useState([]);
  useEffect(() => {
    if (!itemResultFilesSuccess) {
      dispatch(fetchItemResultFiles());
    }
    return () => {
      dispatch(resetBlockFlags({ blockType: 'updateItemResults' }));
      dispatch(resetBlockFlags({ blockType: 'updateItemResultFiles'}));
      dispatch(resetBlockFlags({ blockType: 'updateInspection'}));
    }
  }, [])

  //Set room and inspection ids
  useEffect(() => {
    let roomId = selectedRoom ? selectedRoom.id : null;
    let inspectionId = roomInspection ? roomInspection.id : null;
    setRoomId(roomId)
    setInspectionId(inspectionId)
  }, [selectedRoom, roomInspection])

  //Fetch items results
  useEffect(() => {
    if (inspectionId && (!itemResultsSuccess || itemResultsData[inspectionId] === undefined)) {
      setQuestionsLoading(true);
      dispatch(fetchItemResults({ inspectionId }));
    }
  }, [inspectionId])

  useEffect(() => {
    if (roomId && (!inspectionsItemSuccess || inspectionItemsData[roomId] === undefined)) {
      setQuestionsLoading(true);
      dispatch(fetchInspectionItems({ roomId }));
    }
  }, [roomId])

  useEffect(() => {
    if ((!inspectionId || itemResultsSuccess) && inspectionsItemSuccess) {
      setQuestionsLoading(false); 
    }
  }, [itemResultsSuccess, inspectionsItemSuccess])

  //Construct item results table
  useEffect(() => {
    if ((itemResultsSuccess || updatedItemResultsSuccess || deleteInspectionSuccess )
    && inspectionsItemSuccess 
    && inspectionId 
    && roomId 
    && inspectionItemsData[roomId]) {
      const itemResults = itemResultsData[inspectionId];
      if (!itemResults || itemResults.length === 0) {
        setQuestionResultsTable({})
        return;
      }
      let newTable = inspectionItemsData[roomId].reduce((table, item) => {
        var result = itemResults.find(({ inspectionItem,roomId}) => {
          return inspectionItem.trim().toLowerCase() === item.item.trim().toLowerCase()&&roomId===item?.room;
        });
        if (!result) {
          return table;
        }
        table[item.id] = { ...result, edited: false };
        return table;
      }, {});
      setQuestionResultsTable(newTable);
    }
  }, [itemResultsSuccess,
    updatedItemResultsSuccess,
    inspectionsItemSuccess,
    deleteInspectionSuccess,
 
    inspectionId,
    roomId])

  //Construct item result files table
  useEffect(() => {
    if ((
    itemResultFilesSuccess  
    || updatedItemResultFilesSuccess
    || deleteItemResultFilesSuccess)
      && itemResultsSuccess
      && inspectionsItemSuccess
      && roomId && inspectionId
      && inspectionItemsData[roomId]
      && itemResultsData[inspectionId]) {

      const inspectionResults = itemResultsData[inspectionId];
      setQuestionFilesTable(itemResultFilesData.reduce((table, resultFile) => {
        let itemResultId = resultFile.itemResult;
        let itemResult = inspectionResults.find(({ id }) => (id === itemResultId))
        if (!itemResult) {
          return table;
        }
        let inspectionItem = inspectionItemsData[roomId].find(({ item }) => itemResult.inspectionItem === item)
        if (!inspectionItem) {
          return table;
        }
        let inspectionItemId = inspectionItem.id
        if (!table[inspectionItemId]) {
          table[inspectionItemId] = [];
        }
        table[inspectionItemId].push({ ...resultFile, edited: false });
        return table;
      }, {}))
    }
  }, [
    itemResultFilesSuccess,
    updatedItemResultFilesSuccess,
    itemResultsSuccess,
    inspectionsItemSuccess,
    deleteInspectionSuccess,
    roomId,
    inspectionId,
  ])


  //Construct questions list
  useEffect(() => {
    if (inspectionsItemSuccess && roomId) {
      let roomInspectionItems = inspectionItemsData[roomId];
      if (!roomInspectionItems) {
        setQuestions([])
      } else {
        setQuestions([...roomInspectionItems]);
      }
    }
  }, [inspectionsItemSuccess, roomId])

  useEffect(() => {
    if (updatedItemResultsSuccess) {
      toast.success('Inspection saved successfully');
    }
  }, [updatedItemResultsSuccess])

  useEffect(() => {
    if (updatedItemResultsError) {
      toast.error('Inspection could not be saved');
    }
  }, [updatedItemResultsError])

  /*
  useEffect(() => {
    const questionsOfType = inspectionQuestions.filter((question) => {
      return question.roomType === selectedRoom.roomType;
    });
    if (results.length) {
      const newQuestions = questionsOfType.map((q) => {
        const result = results.find((r) => r.parameter === q.id);
        return {
          ...q,
          status: result.status,
          note: result.note,
          filesOfParameterResult: result.filesOfParameterResult.map(
            (i) => i.file
          ),
        };
      });
      return setQuestions(newQuestions);
    }
    setQuestions(
      questionsOfType.map((que) => ({
        ...que,
        status: "",
        note: "",
        filesOfParameterResult: [],
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoom.id]);

    */
  useEffect(() => {
    // let totalItems = questions.length;
    let score = questions.reduce((score, question) => {
      let itemResult = getQuestionResult(question.id);
      const result = itemResult?.result=== 1? itemResult.result:0;
      return score + result;
    }, 0);
    let totalItems = questions.reduce((totalQuestions, question) => {
      let itemResult = getQuestionResult(question.id);
      let result;
      if(canSaveInspection){
        result=itemResult?.result!==3?1:0;

      }
      else {
        result= typeof itemResult?.result!=="undefined"&& itemResult?.result!==3?1:0
      }
      return totalQuestions + result;
    }, 0);
    setInspectionScore({score, totalItems})
    const scoreText = `${score}/${totalItems}`;
    setScoreText(scoreText)


    /*
    Promise.resolve(dispatch(startBlockFlags({ blockType: 'updateInspection' }))).then(() => {
      let score = questions.reduce((score, question) => {
        let itemResult = getQuestionResult(question.id);
        const result = !itemResult ? 0 : itemResult.result;
        return score + result;
      }, 0);
      let totalItems = questions.length;
      dispatch(updateInspectionSuccess({
        ...roomInspection,
        score,
        totalItems,
      }));
    })
    */
  }, [questions, questionResultsTable, roomInspection])

  const handleChange = (id) => ({ target: { name, value } }) => {
    if (name.startsWith('passFail-')) {
      let resultValue = parseInt(value);
      let itemResult = questionResultsTable[id];
      if (itemResult) {
        itemResult.result = resultValue;
      } else {
        itemResult = {
          result: resultValue,
        }
      }
      let newTable = {
        ...questionResultsTable
      };
      itemResult.edited = true;
      newTable[id] = itemResult;
      setQuestionResultsTable(newTable)
    } else {
      let itemResult = questionResultsTable ? questionResultsTable[id] : undefined;
      if (!itemResult) {
        itemResult = { [name]: value };
      } else {
        itemResult[name] = value;
      }
      itemResult.edited = true;
      setQuestionResultsTable({
        ...questionResultsTable,
        [id]: itemResult,
      });
    }
  };

  const handleRemoveFile = (questionId, index) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    let files = getQuestionFiles(questionId);
    let removed = files[index];
    if (removed.id) {
      setRemovedFiles([
        ...removedFiles,
        removed,
      ]);
    }
    let newFiles = [...files];
    newFiles.splice(index, 1)
    setQuestionFilesTable({
      ...questionFilesTable,
      [questionId]: newFiles,
    })
  }
  useEffect(() => {
    if (deleteItemResultFilesSuccess) {
      setRemovedFiles([]);
    }
  }, [deleteItemResultFilesSuccess])

  const handleFileChange = (id) => async ({ target }) => {
    const base64FilesWithNames = [];
    const {files} = target
    for (let file of files) {
      const base64 = await convertToBase64(file);
      base64FilesWithNames.push({
        "name": file.name,
        "file": base64,
      });
    }
    addQuestionFiles(id, base64FilesWithNames);
  };
  const handleSaveResultClick = async () => {
    let errorMsg = "";
    questions.forEach((que) => {
      let itemResult = getQuestionResult(que.id);
      if (![0, 1,3].includes(itemResult?.result)) {
        errorMsg = "Please provide all inspection results.";
      }
    });
    if (errorMsg) {
      return toast.error(errorMsg);
    }
    setRoomListLoading(true);
    let inspectionId = roomInspection ? roomInspection.id : undefined;
    if (!inspectionId) {
      const inspection = await dispatch(createInspection({roomId}));
      inspectionId = inspection.id;
    }
    
    let roomTypeId = selectedRoom.roomType;
    let items = [];

    questions.forEach((question) => {
      let itemResult = getQuestionResult(question.id);
      let files = getQuestionFiles(question.id)

      items.push({
        id: itemResult && itemResult.id ? itemResult.id : null,
        inspectionItem: question.item,
        roomType: roomTypeId,
        result: itemResult.result,
        inspection: inspectionId,
        note: getQuestionNote(question.id),
        files: files ? files : [],
      })
    });
    await dispatch(deleteItemResultFiles({resultFiles: removedFiles}));
    await dispatch(saveInspection({ items, inspectionId }))
    setRoomListLoading(false)

  };
  function getQuestionFiles(id) {
    return !questionFilesTable ? [] : questionFilesTable[id] || [];
  }
  function getQuestionNote(id) {
    return !questionResultsTable ? '' : questionResultsTable[id]?.note || '';
  }
  function getQuestionResult(id) {
    return !questionResultsTable ? undefined : questionResultsTable[id];
  }
  function addQuestionFiles(id, files = []) {
    let resultFiles = getQuestionFiles(id);
    resultFiles = [...resultFiles, ...files.map((file) => ({ ...file, edited: true }))]

    let newTable = { ...questionFilesTable };
    newTable[id] = resultFiles;
    setQuestionFilesTable(newTable)
  }
  /*
  useEffect(() => {
    //console.log(`imageTable`, questionImagesTable)
  }, [questionImagesTable])
  */
 let isOwner=true;
 if(roomInspection?.inspectedBy){
 isOwner= roomInspection?.inspectedBy=== user?.id

 }
 const navigateToInspection = () => {
  const roomInspectionString = JSON.stringify(roomInspection);
  localStorage.setItem("inspectionReportData", roomInspectionString);
  navigate(`/printInspection`);
};
  return (
    <div className="section-details position-relative">
      <InnerLoading show={questionsLoading}></InnerLoading>
      {!(questionsLoading) && (
        <>
          <div className="d-flex flex-column flex-md-row pl15 pr15 section-details-header align-items-md-start align-items-end justify-content-between">
            <header className="order-3 order-md-1">
              <h5 className="section-title">{selectedRoom.name} Inspection</h5>
              <p className="list-label section-list-label mb-0">
                Select pass or fail for the below parameters and add notes for
                failed parameters
              </p>
            </header>
            {roomInspection?.id && roomInspection.inspectedBy === user.id && (
            <button 
              className="order-1 mb-4 mb-md-0 order-md-2 ms-auto me-4 btn btn-outline-secondary d-flex align-items-center text-uppercase shadow-none"
              onClick={onOpenDeleteInspection(roomInspection)}
              title="Delete this inspection"
            >
              <i className="me-2 fa-sharp fa-solid fa-trash"></i>
              Delete Inspection
            </button>
            )}
           
            <button 
              className="order-2 mb-4 mb-md-0 order-md-2 ms-auto me-4 btn btn-outline-secondary d-flex align-items-center text-uppercase shadow-none"
              onClick={navigateToInspection}
            >
            <i className="me-2 fas fa-file"></i>
               Inspection Report
            </button>
          
            <div className="order-2 mb-4 mb-md-0 order-md-3 d-flex flex-column section-right-title justify-content-end">
              Points<br/>
              <div className="list-content text-primary mt-2 text-center list-content-title">
                {scoreText}
              </div>
            </div>
          </div>
          <div className="section-details-content">
            <div className="inner-tab-section-list-holder pt-4">
              <ul className="section-list-block p-0 m-0">
                {questions.length > 0 &&
                  questions.map((question) => (

                    <li
                      className="inner-tab-section-list-item section-without-hover"
                      key={`inspectionItem${question.id}`}
                    >
                      <div className="d-flex justify-content-between">
                        <div className="list-label me-2">
                          {question.item}
                        </div>
                        <div className="d-flex justify-content-end" style={{ flex: '0 0 auto' }}>
                          {canSaveInspection && (
                            <div className="form-check me-4">
                              <input
                                className="form-check-input form-radio-input"
                                type="radio"
                                name={`passFail-${question?.id}`}
                                value={1}
                                checked={getQuestionResult(question?.id)?.result === 1}
                                onChange={handleChange(question?.id)}
                              />
                              <label className="form-check-label mr-5">Pass</label>
                            </div>
                          )}
                          {canSaveInspection && (
                            <div className="form-check  me-4 ">
                              <input
                                className="form-check-input form-radio-input"
                                type="radio"
                                name={`passFail-${question?.id}`}
                                value={0}
                                checked={getQuestionResult(question?.id)?.result === 0}
                                onChange={handleChange(question?.id)}
                              />
                              <label className="form-check-label mr-5">Fail</label>
                            </div>
                          )}
                          {canSaveInspection && (
                            <div className="form-check">
                              <input
                                className="form-check-input form-radio-input"
                                type="radio"
                                name={`passFail-${question?.id}`}
                                value={3}
                                checked={getQuestionResult(question?.id)?.result === 3}
                                onChange={handleChange(question?.id)}
                              />
                              <label className="form-check-label mr-3">N/A</label>
                            </div>
                          )}
                          {!canSaveInspection && getQuestionResult(question.id)?.result === 1 && (
                            <b><i className="fa-solid fa-check me-2"></i>Pass</b>
                          )}
                          {!canSaveInspection && getQuestionResult(question.id)?.result === 0 && (
                            <b><i className="fa-solid fa-x me-2  text-danger"></i>Fail</b>
                          )}
                           {!canSaveInspection && getQuestionResult(question.id)?.result === 3 && (
                            <b><i className="fa-solid fa-x me-2  text-secondary"></i>N/A</b>
                          )}
                          {!canSaveInspection && getQuestionResult(question.id) === undefined && (
                            <b>Pending</b>
                          )}
                        </div>
                      </div>
                      {getQuestionResult(question.id)?.result === 0 && (
                        <FailBlock
                          canSaveInspection={canSaveInspection}
                          question={question}
                          note={getQuestionNote(question.id)}
                          files={getQuestionFiles(question.id)}
                          handleRemoveFile={handleRemoveFile}
                          handleChange={handleChange}
                          handleFileChange={handleFileChange}
                          isOwner={isOwner}
                        />
                      )}
                    </li>
                  ))}
              </ul>
            </div>
            {canSaveInspection && (
              <div className="inspection-save-question d-flex justify-content-end">
                <button
                  className="btn btn-primary m-4 me-3 px-5"
                  disabled={updatedItemResultsLoading}
                  onClick={handleSaveResultClick}
                >
                  {updatedItemResultsLoading ? 'Saving...' : 'Save'}
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default InspectionForm;
