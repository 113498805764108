import React, { useState, useRef, useEffect } from "react";
import "./printInspectionReport.scss";
import { Row, Table, Container } from "react-bootstrap";
import { FileText } from 'react-bootstrap-icons';
import InnerLoading from "components/InnerLoading";
import logo from "../../assets/images/logo.png";
import ReactToPrint from "react-to-print";
import moment from "moment";
import NavBar from "components/MainNavbar";
import { handleDownloadFile, isImage } from "utils/utility";
const PrintInspectionReport = () => {
  const [parsedData, setParsedData] = useState();

  const contentRef = useRef(null);
  const [loading,setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    const dataString = localStorage.getItem("inspectionReportData");
    if (dataString) setParsedData(JSON.parse(dataString));
    setLoading(false);
  }, []);


  return (
    <Container fluid>
      <NavBar />
      <Row>
        {loading ? (
          <InnerLoading show={loading} />
        ) : parsedData?.itemResults?.length > 0 ? (
          <>
            <div>
              <ReactToPrint
                trigger={() => (
                  <button className="mt-5 mx-auto btn btn-outline-secondary d-flex align-items-center text-uppercase shadow-none">
                    <i className="me-2 fas fa-print"></i>
                    Print Inspection Report
                  </button>
                )}
                content={() => contentRef.current}
                pageStyle="@page { size: A4; margin: 0; } @media print { body { margin: 0; } * { -webkit-print-color-adjust: exact !important; print-color-adjust: exact !important; } }"
              />
            </div>
            <div className="reportDetailContainer" ref={contentRef}>
              <img
                src={logo}
                height={100}
                width={"auto"}
                className="mt-5 mb-5  mx-auto d-block"
              />
              <p className="para">
                <span className="heading">District: </span>
                {`${parsedData?.districtName || "-"}`}
              </p>
              <p className="para">
                <span className="heading">Building: </span>
                {`${parsedData?.buildingName || "-"}`}
              </p>
              <p className="para">
                <span className="heading">Section: </span>
                {`${parsedData?.section || "-"}`}
              </p>
              <p className="para">
                <span className="heading">Inspected By: </span>
                {`${parsedData?.inspectedByName || "-"}`}
              </p>
              <p className="para">
                <span className="heading">Room: </span>
                {`${parsedData?.roomName || "-"}`}
              </p>
              <p className="para">
                <span className="heading">Score: </span>
                {`${parsedData?.score || "-"}`}
              </p>
              <p className="para">
                <span className="heading">Cleaner Name: </span>
                {`${parsedData?.cleanerName || "-"}`}
              </p>
              <p className="para">
                <span className="heading">Created On: </span>
                {`${
                  moment(parsedData?.created).format("DD/MM/YYYY h:mmA") || "-"
                }`}
              </p>
              <p className="para">
                <span className="heading">Last Updated: </span>
                {`${
                  moment(parsedData?.Updated).format("DD/MM/YYYY h:mmA") || "-"
                }`}
              </p>
              <Table bordered className="inspection-item-table">
                <thead>
                  <tr>
                    <th>Inspection Item</th>
                    <th>Note</th>
                    <th>Result</th>
                    <th>Files</th>
                  </tr>
                </thead>
                <tbody>
                  {parsedData?.itemResults.filter((item)=>item.result!==3)?.map((item) => {
                    return (
                      <tr>
                        <td> {item?.inspectionItem} </td>
                        <td> {item?.note} </td>
                        <td> {item.result === 0 ? "Fail" :item?.result===3?"NA": "Pass"}</td>
                        <td className="image-row">
                          <div className="d-flex  align-content-start flex-wrap justify-content-lg-start justify-content-center">
                          {item.files.map((file, index) => (
                            <div key={index} className="mb-2">
                            {isImage(file.file)? <img
                              key={index}
                              src={file.file}
                              className="d-block mb-2 inspection-item-image pe-2"
                            /> : <a  href={file.file} onClick={() => handleDownloadFile(file.file, file.name)}><FileText size={150} />
                              <div className="file-title" >
                                {file.name || ""}
                              </div>
                            </a>}
                            </div>
                          ))}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </>
        ) : (
          <p className="text-center text-danger"> Some Thing Went Wrong</p>
        )}
      </Row>
    </Container>
  );
};

export default PrintInspectionReport;
