import BaseModal from "components/BaseModal";
import React, { useState } from "react";
import { FileText } from 'react-bootstrap-icons';
import {handleDownloadFile, isImage} from 'utils/utility';
const FailBlock = ({
  question,
  files = [],
  note = "",
  handleChange,
  handleFileChange,
  canSaveInspection,
  handleRemoveFile,
  isOwner,
}) => {
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [file, setFile] = useState("");
  const onOpenPreviewModal = (file) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenPreviewModal(true);
    setFile(file);
  };
  const onClosePreviewModal = () => {
    setOpenPreviewModal(false);
    setFile("");
  };

  return (
    <>
      <div className="pt-4">
        {canSaveInspection && (
          <div className="form-floating mb-4 go-bottom">
            <textarea
              required
              className="form-control inspection-note-textarea"
              name="note"
              id="inspection note"
              rows={3}
              value={note}
              onChange={handleChange(question.id, "note")}
              placeholder="Inspection notes"
            ></textarea>
            <label htmlFor="name">Inspection notes</label>
          </div>
        )}
        {!canSaveInspection && note && (
          <div>
            <h6>Note</h6>
            <p>{note}</p>
          </div>
        )}
        {canSaveInspection && (
          <div className="d-flex justify-content-between pb-4 align-items-center">
            <div className="section-header">
              <h6 className="section-file-title">Inspection Files</h6>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <input
                multiple
                type="file"
                name="questionResultFile"
                onChange={handleFileChange(question.id)}
                id={`select-file-${question.id}`}
                className="btn btn-outline-secondary"
                style={{ display: "none" }}
              />
              <label
                htmlFor={`select-file-${question.id}`}
                className="btn btn-outline-secondary px-4 py-2 d-flex align-items-center text-uppercase"
              >
                Select files
              </label>
            </div>
          </div>
        )}

        {files.length > 0 && (
          <div className="section-file-holder mb-4">
            <div
              style={{ marginTop: "-8px", marginLeft: "-8px" }}
              className="d-flex flex-wrap justify-content-lg-start justify-content-center"
            >
              {files.map((file, i) => (
                
                <div
                  key={`questionFile${file.file}${question.id}${i}`}
                  className="item-result-file position-relative mt-2 ms-2"
                >
                  {isImage(file.file)?
                  <div>
                    <img
                      src={file.file}
                      onClick={onOpenPreviewModal(file.file)}
                      alt="Question Result File"
                      className="file-responsive section-file ms-0 me-0"
                    />
                    <div class='text-center'>{file.name || ""}</div>
                  </div>: 
                  <a onClick={() => handleDownloadFile(file.file, file.name)}>
                    <FileText size={150} />
                    <div class="file-title" >
                      {file.name || ""}
                    </div>
                  </a>}
                  {isOwner && (
                    <a
                      href="#"
                      onClick={handleRemoveFile(question.id, i)}
                      className="position-absolute top-0 end-0 me-2 mt-1 shadow-sm rounded-circle overflow-hidden"
                    >
                      <i
                        className="fa-sharp fa-solid fa-circle-xmark rounded-circle"
                        style={{ background: "#fff" }}
                      ></i>
                    </a>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {openPreviewModal && (
        <BaseModal
          onClose={onClosePreviewModal}
          show={openPreviewModal}
          className="text-center"
          size="xl"
          formWrapper={false}
          showFooter={false}
          headerProps={{
            closeButton: true,
            className:
              "pt-0 position-absolute mb-0 top-0 end-0 mt-3 me-0 cursor-pointer",
            style: { zIndex: 10 },
          }}
          content={
            <img
              className="img-fluid mt-2 mt-lg-0"
              src={file}
              alt="Map File Preview"
              style={{ maxHeight: "80vh" }}
            />
          }
        />
      )}
    </>
  );
};

export default FailBlock;
